import PropTypes from 'prop-types';
import { Link as ScrollLink } from 'react-scroll';
import React, { useState } from 'react';
import './Header.scss';
import logo from '../../sass/images/logo-without-slogan.svg';

const duration = 100;

const Header = () => {
  const [mobileToggle, setMobileToggle] = useState(false);

  const handleToggleMenu = () => {
    setMobileToggle(!mobileToggle);
  }

  return (
    <header className={`st-site-header st-style1 st-sticky-header`}>
      <div className="st-main-header">
        <div className="container">
          <div className="st-main-header-in">
            <div className="st-main-header-left">
              <ScrollLink className="cursor-pointer" to="home" spy={true} smooth={false} offset={-80} duration={duration} onClick={() => setMobileToggle(false)}>
                <img src={logo} style={{ width: 200 }} alt="fake detecta" />
              </ScrollLink>
            </div>
            <div className="st-main-header-right">
              <div className="st-nav">
                <ul className="st-nav-list st-onepage-nav" style={{ display: `${mobileToggle ? 'block' : 'none'}` }}>
                  <li><ScrollLink to="home" spy={true} smooth={false} offset={-80} duration={duration} onClick={() => setMobileToggle(false)}>Home</ScrollLink></li>
                  <li><ScrollLink to="about" spy={true} smooth={true} offset={-80} duration={duration} onClick={() => setMobileToggle(false)}>About</ScrollLink></li>
                  <li><ScrollLink to="features" spy={true} smooth={true} offset={-80} duration={duration} onClick={() => setMobileToggle(false)}>Features</ScrollLink></li>
                  {/* <li><ScrollLink to="contact" spy={true} smooth={true} offset={-80} duration={duration} onClick={() => setMobileToggle(false)}>Contact</ScrollLink></li> */}
                </ul>
                <div className={`st-munu-toggle ${mobileToggle ? "st-toggle-active" : ""} `} onClick={handleToggleMenu}>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  data: PropTypes.object
}

export default Header;
