import React from 'react';
import data from '../HomePageData.json';
import Hero from '../components/Hero/Hero';
import About from '../components/About/About';
import Iconbox from '../components/Iconbox/Iconbox';
// import Contact from "../components/Contact/Contact";

const Home = () => {
  const { heroData, aboutData, serviceData, /* contactData, */ socialData } = data;
  return (
    <>
      <Hero data={heroData} socialData={socialData} data-aos="fade-right" />
      <About data={aboutData} data-aos="fade-right" />
      <Iconbox data={serviceData} data-aos="fade-right" />
      {/* <Contact data={contactData} socialData={socialData} data-aos="fade-right" /> */}
    </>
  )
}


export default Home;
